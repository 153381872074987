<template>
	<div>
		<div class="xianm">
			<div :class="columnIndex == 0 ? 'name name2':'name'" @click="column(0)">所有</div>
			<div :class="columnIndex == 2 ? 'name name2':'name'" @click="column(2)">报名中</div>
			<div :class="columnIndex == 1 ? 'name name2':'name'" @click="column(1)">报名未开始</div>
			<div :class="columnIndex == 3 ? 'name name2':'name'" @click="column(3)">报名已结束</div>
		</div>
		
		<div class="course-box">
			<div class="course" v-for="(item,index) in data" :key="index" @click="getdetails(item)">
				<div class="introduction">
					<img class="img" :src="item.image" alt="">
				</div>
				<div class="name-container">
					<p class="name">{{item.matchName}}</p>
				</div>
				<div class="line" style="margin: 0;"></div>
				<div class="specific-box">
					<div class="specific">
						<img class="img" v-if="item.status == 2" src="../../assets/image/date2.png" alt="">
						<img class="img" v-else src="../../assets/image/date1.png" alt="">
						<div class="information">
							<div class="information-title">报名时间</div>
							<div class="information-date">{{item.registerDateStart | format}} 至 {{item.registerDateEnd | format}}</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific">
						<img class="img" v-if="item.status == 2" src="../../assets/image/addes2.png" alt="">
						<img class="img" v-else src="../../assets/image/addes1.png" alt="">
						<div class="information">
							<div class="information-title">比赛地点</div>
							<div class="information-date">{{item.matchAddressStadium || '-' }}</div>
						</div>
					</div>
					<div class="button" :style="{'background':item.status == 2 ? '#0A6DF3':'#C8D5E7'}">
						{{ item.status == 2 ? '报名中':(item.status == 3 ? '已结束':'报名未开始') }}
					</div>
				</div>
			</div>
		</div>
		
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			id: {
				type: [Number, String],
				default: ''
			},
			pid: {
				type: [Number, String],
				default: ''
			},
		},
		data() {
			return {
				columnIndex: 0,
				start: true,
				total: 0,
				info: {
					isOnlineRegister: 0,
					pageNo: 1,
					pageSize: 12,
					status:0,
					matchName:''
				},
				data: []
			}
		},
		created() {
			this.getMatchBasicInfo()
		},
		filters: {
			format(x) {
				if(x == '' || x == null || x == undefined){
					return ''
				}else{
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(val) {
				this.info.pageNo = val
				this.getMatchBasicInfo()
			},
			column(e) {
				this.columnIndex = e
				this.info.status = e
				this.getMatchBasicInfo()
			},
			getMatchBasicInfo() {
				this.$api.getMatchBasicInfo(this.info).then(res => {
					res.data.data.result.records.forEach(item=>{
						item.matchAddressStadium = item.addresses.map(item=>{
							return item.matchAddressStadium
						}).toString()
						this.$api.getFile({objectName:item.imgs[0]}).then(res=>{
							this.$convert(res.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					})
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				})
			},
			getdetails(row) {
				this.$router.push({
					path: '/h5/H5application',
					query: {
						id: row.id,
						refresh: new Date().getTime(),
						type: 1,
						columnId: this.columnId,
						columnPid: this.columnPid
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.xianm {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 14PX 10PX 0;
		background: #F0F7FF;
		border: 1PX solid #4497F8;
		border-radius: 9PX;
	
		.name {
			display: flex;
			font-size: 13PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #1E71EF;
			padding: 10PX;
		}
	
		.name:nth-child(3n) {
			margin-right: 0;
		}
	
		.name2 {
			color: #FFFFFF;
			background: #0A6DF3;
			border-radius: 8PX;
			height: 100%;
			padding: 10PX;
		}
	}
	
	.course-box {
		display: flex;
		flex-wrap: wrap;
		// justify-content: flex-start;
		justify-content: space-between;
		margin: 24PX 10PX 0;
	}
	
	.course {
		width: 48%;
		// height: 241PX;
		border-radius: 17PX;
		margin-bottom: 16PX;
	}
	
	.course:nth-child(2n) {
		margin-right: 0;
	}
	
	.introduction {
		cursor: pointer;
	}
	
	.introduction img {
		width: 100%;
		height: 118PX;
		border-radius: 17PX 17PX 0PX 0PX;
	
	}
	
	
	.name-container {
		height: 42PX;
		background: rgba(236, 241, 253, 0.65);
		font-size: 13PX;
		font-weight: bold;
		color: #164B92;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	
	.name-container .name {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		padding: 0 5PX;
	}
	
	// .introduction .name {
	// 	position: absolute;
	// 	width: 45.5%;
	// 	height: 42PX;
	// 	background: rgba(0, 0, 0, 0.67);
	// 	font-size: 14PX;
	// 	font-weight: bold;
	// 	color: #FFFFFF;
	// 	text-align: center;
	// 	margin-top: 76PX;
	// 	overflow: hidden;
	// 	text-overflow: ellipsis;
	// 	display: -webkit-box;
	// 	-webkit-line-clamp: 2;
	// 	-webkit-box-orient: vertical;
	// 	padding: 0 5PX;
	// 	padding-top: 2PX;
	// }
	
	.specific-box {
		// height: 122PX;
		background: rgba(236, 241, 253, 0.65);
		border-radius: 0PX 0PX 17PX 17PX;
		cursor: pointer;
		padding-bottom: 5PX;
	}
	
	.specific-box .specific {
		display: flex;
	}
	
	.specific-box .specific .img {
		width: 23PX;
		height: 23PX;
		margin-top: 6PX;
		margin-left: 14PX;
	}
	
	.specific-box .specific .information {
		margin-left: 11PX;
	}
	
	.specific-box .specific .information .information-title {
		font-size: 12PX;
		font-weight: bold;
		color: #164B92;
		margin-top: 6PX;
	}
	
	.specific-box .specific .information .information-date {
		width: 115PX;
		font-size: 10PX;
		font-weight: 500;
		color: #333333;
		margin-top: 6PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
	
	.specific-box .button {
		background: #0A6DF3;
		border-radius: 18PX;
		font-size: 12PX;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		margin: 5PX 30PX;
		padding: 3PX 20PX;
	}
	
	.line {
		width: 163PX;
		height: 1PX;
		background: #DADADB;
		opacity: 0.63;
		margin-top: 7PX;
	}
	
	.paging {
		margin-left: 18PX;
		margin-bottom: 40PX;
		display: flex;
		align-items: center;
	}
	
	.paging span {
		margin-right: 5PX;
	}
</style>